"use client";
import PropTypes from "prop-types";
import parser from "html-react-parser";
import {
  useSelectCsrfToken,
  useSelectLocale,
  useSelectReadyStore,
  useSelectGameGroups,
} from "@/hooks";
import { useState, useEffect } from "react";
import sendInternalPostRequest from "@/services/client/requests/sendInternalPostRequest";
import { formatNumberWithComma } from "@/utils/functions.client";
import { useSelector } from "react-redux";

export default function HomeWinnersMobile({ label = "" }) {
  const readyStore = useSelectReadyStore();
  const csrfToken = useSelectCsrfToken();
  const subgameGroupId = useSelector((state) => state.grandDraw.subgameId);
  const [totalWinners, setTotalWinners] = useState(0);
  const locale = useSelectLocale();
  const gameGroups = useSelectGameGroups();
  const gameId = gameGroups[0]?.gameID;
  const currency = gameGroups?.[0]?.gamePrice?.currencyCode;
  useEffect(() => {
    async function getTotalWinners() {
      // First API call with gameId
      const results = await sendInternalPostRequest({
        endpoint: "/api/total-winners",
        data: { currencyCode: currency, gameId },
        csrfToken,
      });
      setTotalWinners(
        results?.result?.statistic?.totalWinCount +
          results?.result?.subGame?.statistic?.totalWinCount
      );
    }

    if (readyStore && csrfToken && currency && gameId) {
      getTotalWinners();
    }
  }, [
    readyStore,
    csrfToken,
    currency,
    gameId,
    subgameGroupId,
    setTotalWinners,
  ]);

  return (
    <div className="col-lg-12">
      <div className="hp-navigation__box box-total mobile">
        <div
          style={{
            borderRight: locale == "en" ? "1px solid white" : "",
            borderLeft: locale == "ar" ? "1px solid white" : "",
            flex: "1",
          }}
        >
          {parser(label || "")}
        </div>
        <div className="hp-navigation__box--price item3">
          <h3 className="digits-iq-bld mb-0">
            {formatNumberWithComma(totalWinners || "")}
          </h3>
        </div>
      </div>
    </div>
  );
}

HomeWinnersMobile.propTypes = {
  /**
   * Data
   */
  label: PropTypes.string.isRequired,
};
